import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import BtnMenu from "../../molecules/btnMenu";
import BtnMas from "../../molecules/btnMas";
import BtnMenos from "../../molecules/btnMenos";
import { useState } from "react";
import { AGENDA_CANCELAR, AGENDA_MODIFICAR } from "../../../routes";
import { Link } from "react-router-dom";

const InfoCovid = () => {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [show10, setShow10] = useState(false);
  const [show11, setShow11] = useState(false);
  const [show12, setShow12] = useState(false);
  const [show13, setShow13] = useState(false);

  return (
    <Row className="flex-grow-1 mr-0 ml-0">
      <Col xs={12} className="p-0">
        <Jumbotron fluid className="jumbo-encabezado-seccion mb-0 py-5">
          <h3 className="mt-4">Información sobre COVID-19</h3>
        </Jumbotron>
      </Col>
      <Container className="mt-3">
        <Row>
          <Col xs={12}>
            <div
              className="d-flex justify-content-between align-items-center pointer"
              onClick={() => setShow1(!show1)}
            >
              <h5 className="mb-0">
                1 - ¿Dónde puedo realizarme el test COVID-19 y horarios?
              </h5>
              {show1 ? <BtnMenos /> : <BtnMas />}
            </div>
            <div className="info-div-container">
              <div className={`info-div-section ${show1 ? "" : "collapsed"}`}>
                
                {/*<p>
                  AUTOTEST LATU (MONTEVIDEO):
                  <br />
                  Ubicado en Av. Saldún de Rodríguez 2037 esquina Bolonia - 
                  Centro de Eventos del LATU.
                  <br />
                  Atención de Lunes a Sabado con agenda previa.
                </p>
                
                <p>
                  AEROPUERTO DE CARRASCO (MONTEVIDEO): <br />
                  Centro de hisopados ubicado en zona de partidas (segundo
                  piso), ala derecha, cercano al local de Mc Café. <br />
                  Atención con agenda previa coordinando por teléfono al (+598) 2 600 6001
                </p>
                <p>
                  AUTOTEST PUNTA DEL ESTE (MALDONADO): <br />
                  Ubicado en el Parking del Hotel Enjoy, entrada por Blvr.
                  Artigas entre Biarritz y Chiverta. <br />
                  Atención de lunes a sábados con agenda previa.
                  </p>*/}
                 <p>
                 Podrás realizarte en:
                  <br />
                  <strong>Laboratorio central ATGen</strong><br /><br/>
                    <strong>Ubicado en</strong>: Av. Italia 6201 - Edificio Los Tilos - Of. 105 dentro del Parque tecnológico LATU. 
                    <br/>Con estacionamiento disponible.
                  <br /><br/>
                  Atención de Lunes a Viernes con agenda previa por la web, teléfono y whatsapp al <strong>26006001</strong> ó mail: <strong>coordinaciones@atgen.com.uy.</strong><br/>
                  Además contamos con sistema de toma de muestra a domicilio en Montevideo y en el interior.<br/><br />
                  Podes agendarte vía telefónica al <strong>(+598) 2 600 6001</strong> ó a través de nuestro mail <strong>coordinaciones@atgen.com.uy</strong>
                </p>
                <p>Los test a domicilio se realizan de Lunes a Sábado.</p>
               
              </div>
            </div>
            <hr />
          </Col>
          <Col xs={12}>
            <div
              className="d-flex justify-content-between align-items-center pointer"
              onClick={() => setShow2(!show2)}
            >
              <h5 className="mb-0">
                2 - ¿Cuánto tarda el resultado de test de PCR y de Antígeno?
              </h5>
              {show2 ? <BtnMenos /> : <BtnMas />}
            </div>
            <div className="info-div-container">
              <div className={`info-div-section ${show2 ? "" : "collapsed"}`}>
                <p>
                  PCR: Para pacientes particulares que se realizaron el test en
                  las sucursales de Montevideo y Maldonado, el
                  resultado se entrega el mismo día de realizado el estudio. Excepto días domingos que el plazo es 24 hs.
                </p>
                <p>
                  ANTIGENO: Para pacientes particulares que se realizaron el
                  test en las sucursales de Montevideo y Maldonado, el
                  resultado se entrega en un plazo menor a 2 horas.
                </p>
              </div>
            </div>
            <hr />
          </Col>
          <Col xs={12}>
            <div
              className="d-flex justify-content-between align-items-center pointer"
              onClick={() => setShow3(!show3)}
            >
              <h5 className="mb-0">3 - ¿Cómo se puede pagar?</h5>
              {show3 ? <BtnMenos /> : <BtnMas />}
            </div>
            <div className="info-div-container">
              <div className={`info-div-section ${show3 ? "" : "collapsed"}`}>
                <p>
                  Se paga al momento de finalizar la reserva en la agenda web, a
                  través de MercadoPago, con cualquier tarjeta de débito o
                  crédito habilitada. Si el pago se realiza con tarjeta de
                  crédito, se permite de 1 hasta 10 cuotas sin intereses.
                </p>
                <p>
                  También se puede pagar al momento de realizarse el test, con
                  tarjeta de crédito/débito VISA, MASTERCARD o AMEX o al contado
                  (con efectivo justo).
                </p>
              </div>
            </div>
            <hr />
          </Col>
          <Col xs={12}>
            <div
              className="d-flex justify-content-between align-items-center pointer"
              onClick={() => setShow4(!show4)}
            >
              <h5 className="mb-0">4 - ¿Cómo se envían los resultados?</h5>
              {show4 ? <BtnMenos /> : <BtnMas />}
            </div>
            <div className="info-div-container">
              <div className={`info-div-section ${show4 ? "" : "collapsed"}`}>
                <p>
                  Si Ud. se realizó el hisopado para el test de detección de
                  SARS-CoV-2 en forma particular en uno de nuestros Auto-Test o
                  en su domicilio, el informe con su resultado será enviado al
                  mail que registró, por favor verificar correo no deseado
                  (Spam).
                </p>
                
                <ol>
                  
                </ol>
                <p>
                  Si Ud. fue agendado a través de su Prestador de Salud, el
                  resultado de su estudio será enviado al mismo.
                </p>
                <p>
                  También puede comunicarse vía e-mail a través de:
                  <a href="mailto:miresultado@atgen.uy">
                    miresultado@atgen.uy
                  </a>{" "}
                  o llamando al <a href="tel:26006001">2600 6001</a>.
                </p>
              </div>
            </div>
            <hr />
          </Col>
          <Col xs={12}>
            <div
              className="d-flex justify-content-between align-items-center pointer"
              onClick={() => setShow5(!show5)}
            >
              <h5 className="mb-0">5 - ¿El MSP recibe mi resultado?</h5>
              {show5 ? <BtnMenos /> : <BtnMas />}
            </div>
            <div className="info-div-container">
              <div className={`info-div-section ${show5 ? "" : "collapsed"}`}>
                <p>
                  Todos los resultados son informados al MSP. La app
                  Coronavirus.uy depende de MSP.{" "}
                </p>
              </div>
            </div>
            <hr />
          </Col>

          <Col xs={12}>
            <div
              className="d-flex justify-content-between align-items-center pointer"
              onClick={() => setShow6(!show6)}
            >
              <h5 className="mb-0">
                6 - Solicitud de corrección de datos en resultado:
              </h5>
              {show6 ? <BtnMenos /> : <BtnMas />}
            </div>
            <div className="info-div-container">
              <div className={`info-div-section ${show6 ? "" : "collapsed"}`}>
                <p>
                  Si algún dato en su informe quedó informado de forma
                  incorrecta, usted puede solicitar la modificación mediante el
                  envío de su documento de identidad a Atención al Cliente, por
                  el teléfono <a href="tel:26006001">2600 6001</a>, o por mail
                  enviando la solicitud al mail{" "}
                  <a href="mailto:miresultado@atgen.uy">miresultado@atgen.uy</a>
                </p>
              </div>
            </div>
            <hr />
          </Col>

          <Col xs={12}>
            <div
              className="d-flex justify-content-between align-items-center pointer"
              onClick={() => setShow7(!show7)}
            >
              <h5 className="mb-0">7 - ¿Puedo saber mi CT (carga viral)?</h5>
              {show7 ? <BtnMenos /> : <BtnMas />}
            </div>
            <div className="info-div-container">
              <div className={`info-div-section ${show7 ? "" : "collapsed"}`}>
                <p>
                  Si su médico o Prestador de Salud lo solicita se lo
                  proporcionaremos.
                </p>
              </div>
            </div>
            <hr />
          </Col>

          <Col xs={12}>
            <div
              className="d-flex justify-content-between align-items-center pointer"
              onClick={() => setShow8(!show8)}
            >
              <h5 className="mb-0">
                8 - ¿Se precisa alguna preparación previa antes del hisopado
                para PCR o antígeno?
              </h5>
              {show8 ? <BtnMenos /> : <BtnMas />}
            </div>
            <div className="info-div-container">
              <div className={`info-div-section ${show8 ? "" : "collapsed"}`}>
                <p>No es necesario ningún tipo de preparación previa.</p>
                <p>
                  Únicamente realizamos hisopados nasofaríngeos a niños mayores
                  de 1 año.
                </p>
              </div>
            </div>
            <hr />
          </Col>

          <Col xs={12}>
            <div
              className="d-flex justify-content-between align-items-center pointer"
              onClick={() => setShow9(!show9)}
            >
              <h5 className="mb-0">9 - ¿Cómo modifico mi agenda?</h5>
              {show9 ? <BtnMenos /> : <BtnMas />}
            </div>
            <div className="info-div-container">
              <div className={`info-div-section ${show9 ? "" : "collapsed"}`}>
                <p>
                  Para modificar la agenda ingrese al siguiente link y complete
                  todos los datos requeridos:{" "}
                  <Link to={AGENDA_MODIFICAR}>
                    https://covid19.atgen.com.uy/agenda/modificar
                  </Link>
                </p>
                <p>
                  Va a recibir un mail automático de ATGen confirmando la
                  recepción del formulario. Revise la bandeja de correo no
                  deseado ya que puede ser filtrado. Una vez realizada la
                  solicitud por esta vía, puede presentarse directamente en el
                  lugar y fecha seleccionada con su número de reserva original.
                </p>
              </div>
            </div>
            <hr />
          </Col>

          <Col xs={12}>
            <div
              className="d-flex justify-content-between align-items-center pointer"
              onClick={() => setShow10(!show10)}
            >
              <h5 className="mb-0">10 - ¿Cómo cancelo mi agenda?</h5>
              {show10 ? <BtnMenos /> : <BtnMas />}
            </div>
            <div className="info-div-container">
              <div className={`info-div-section ${show10 ? "" : "collapsed"}`}>
                <p>
                  Si precisa cancelar su reserva, puede hacerlo completando sus
                  datos en el siguiente formulario:{" "}
                  <Link to={AGENDA_CANCELAR}>
                    https://covid19.atgen.com.uy/agenda/cancelar
                  </Link>
                </p>
                <p>
                  Usted tiene derecho a revocar la presente contratación, sin
                  responsabilidad alguna, debiendo para ello comunicarlo en un
                  plazo de hasta 24 horas antes de la hora agendada. Vencido
                  dicho plazo, la revocación generará un costo equivalente al
                  100 % del monto de los servicios contratados. El reintegro se
                  realizará por la misma vía por la que se efectuó el pago. El
                  tiempo que demore en acreditarse el reembolso depende de cada
                  tarjeta y/o Banco emisor que opere en cada caso. Si Ud. ha
                  modificado su fecha y hora de agenda, se tomará en cuenta la
                  fecha y hora de la reserva original para determinar si se
                  cumple con el criterio de cancelación.
                </p>
                <p>
                  Va a recibir un mail automático de ATGen confirmando la
                  recepción del formulario. Revise la bandeja de correo no
                  deseado ya que puede ser filtrado. Una vez realizada la
                  solicitud por esta vía, el tiempo estimado para recibir el
                  reembolso es de 30 días hábiles.
                </p>
              </div>
            </div>
            <hr />
          </Col>

          <Col xs={12}>
            <div
              className="d-flex justify-content-between align-items-center pointer"
              onClick={() => setShow11(!show11)}
            >
              <h5 className="mb-0">11 - Test anticuerpos-serológico:</h5>
              {show11 ? <BtnMenos /> : <BtnMas />}
            </div>
            <div className="info-div-container">
              <div className={`info-div-section ${show11 ? "" : "collapsed"}`}>
                <p>Para que sirve test de anticuerpos:</p>
                <p>
                  Se recomienda para detectar la presencia de anticuerpos en
                  sangre contra SARS-CoV-2 o para evaluar la respuesta
                  inmunitaria frente a las vacunas de COVID-19.
                </p>
                <p>
                  Detección cuantitativa de anticuerpos IgG dirigidos contra la
                  proteina S (Spike) del SARS-CoV-2
                </p>
                <p>Técnica utilizada: ELISA.</p>
                <p>
                  A diferencia de la cualitativa, se informa un valor (cantidad)
                  de anticuerpos IgG presentes en sangre, expresado en unidades
                  internacionales.
                </p>
                <p>
                  Desarrollado junto con la UdelaR y el Institut Pasteur de
                  Montevideo.
                </p>
                <p>Tipo de muestra: sangre obtenida por punción venosa.</p>
                <p>Indicaciones para prueba Anticuerpos:</p>
                <ul>
                  <li>Para la toma de muestra debe descender del vehículo.</li>
                  <li>Se requiere un mínimo de 4 horas de ayuno.</li>
                  <li>
                    No debe asistir si se encuentra cursando síntomas
                    compatibles con COVID-19.
                  </li>
                  <li>
                    El resultado se envía por mail en un plazo máximo de 48
                    horas.
                  </li>
                </ul>
                <p>
                  Para agendar tu estudio comunicarse llamando al 2600 6001 
                   vía mail info@atgen.com.uy</p>
              </div>
            </div>
            <hr />
          </Col>

          <Col xs={12}>
            <div
              className="d-flex justify-content-between align-items-center pointer"
              onClick={() => setShow12(!show12)}
            >
              <h5 className="mb-0">12 - Reclamo de factura:</h5>
              {show12 ? <BtnMenos /> : <BtnMas />}
            </div>
            <div className="info-div-container">
              <div className={`info-div-section ${show12 ? "" : "collapsed"}`}>
                <p>
                  Si usted aun no recibió su factura puede contactarse brindando
                  los datos de la reserva al mail{" "}
                  <a href="mailto:facturacion@atgen.com.uy">
                    facturacion@atgen.com.uy
                  </a>
                </p>
              </div>
            </div>
            <hr />
          </Col>

          <Col xs={12}>
            <div
              className="d-flex justify-content-between align-items-center pointer"
              onClick={() => setShow13(!show13)}
            >
              <h5 className="mb-0">13 - Convenios:</h5>
              {show13 ? <BtnMenos /> : <BtnMas />}
            </div>
            <div className="info-div-container">
              <div className={`info-div-section ${show13 ? "" : "collapsed"}`}>
                <p>
                  ATGen cuenta con convenio con las siguientes instituciones,
                  obteniendo un descuento en la realización del test COVID-19:
                </p>
                <ul>
                  <li>Asociación Española</li>
                  <li>Assist Card</li>
                  <li>Blue Cross</li>
                  <li>Buquebus</li>
                  <li>COSEM</li>
                  <li>Hospital Británico</li>
                  <li>LATU</li>
                  <li>Medicina Personalizada </li>
                  <li>SMI</li>
                  <li>Summum</li>
                </ul>
                <p>
                  Agenda tu estudio con precio bonificado llamando al 2600 6001 via mail info@atgen.com.uy
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
            <div className="d-flex justify-content-center">
              <BtnMenu />
            </div>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

export default InfoCovid;
